import React from 'react';
import cx from 'classnames';
import HamburgerMenu from 'react-hamburger-menu';
import styles from './Menu.module.scss';

const BurgerMenu = ({ onClick, active, asCSS, style }) => (
  <div className={cx(styles.burgerMenu, style)}>
    <HamburgerMenu
      isOpen={active}
      menuClicked={onClick}
      width={28}
      height={16}
      strokeWidth={2}
      color={asCSS ? 'black' : 'white'}
      borderRadius={0}
      animationDuration={0.3}
    />
  </div>
);

export default BurgerMenu;
