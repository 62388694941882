import React from 'react';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { StickyContainer } from 'react-sticky';
import '../../assets/scss/main.scss';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu';
import styles from './Layout.module.scss';

class Layout extends React.Component {
  render() {
    const { children, isMenuVisible, menuClick } = this.props;

    const timeout = 300;

    return (
      <React.Fragment>
        <PageTransition
          defaultStyle={{
            transition: `opacity ${timeout}ms cubic-bezier(0.47, 0, 0.75, 0.72)`,
            opacity: 0,
          }}
          transitionStyles={{
            entering: {
              opacity: 0,
            },
            entered: {
              transition: `opacity ${timeout}ms ease-in-out`,
              opacity: 1,
            },
            exiting: {
              transition: `opacity ${timeout}ms ease-in-out`,
              opacity: 0,
            },
          }}
        >
          <div className={styles.outerWrapper}>
            <StickyContainer>
              {children}
              <Footer />
            </StickyContainer>
            <Menu isMenuVisible={isMenuVisible} onMenuClick={menuClick} />
          </div>
        </PageTransition>
      </React.Fragment>
    );
  }
}

export default Layout;
