import cx from 'classnames';
import React from 'react';
import { Fade } from 'react-reveal';
import { Link } from 'react-scroll';
import styles from './DownAnchor.module.scss';

const DownAnchor = ({ className, to, offset = 0, light, delay = 200 }) => {
  return (
    <Fade duration={500} delay={delay} offset={-250} bottom>
      <Link
        className={cx(
          'element',
          styles.downAnchor,
          light ? styles.light : styles.dark,
          className
        )}
        to={to}
        spy={true}
        offset={offset}
        smooth={true}
        duration={500}
      />
    </Fade>
  );
};

export default DownAnchor;
