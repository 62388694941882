import cx from 'classnames';
import { graphql, Link, navigate, StaticQuery, withPrefix } from 'gatsby';
import React from 'react';
import { Fade } from 'react-reveal';
import { scroller } from 'react-scroll';
import { getFrontmatter } from '../../helpers/helpers';
import BurgerMenu from './BurgerMenu';
import styles from './Menu.module.scss';

const Menu = props => {
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "index" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  path
                  title
                  menuItems {
                    text
                    to
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { menuItems } = getFrontmatter(data);

        const handleClick = (e, val) => {
          e.preventDefault();
          const isHomepage = window.location.pathname === withPrefix('/');

          if (!isHomepage) {
            navigate(`/`);
          }

          setTimeout(
            () => {
              scroller.scrollTo(val.to, {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart',
              });
            },
            isHomepage ? 0 : 200
          );

          props.onMenuClick();
        };

        return (
          <React.Fragment>
            {props.isMenuVisible && (
              <button onClick={props.onMenuClick} className={styles.overlay} />
            )}
            <div
              className={cx(styles.nav, {
                [styles.navOpen]: props.isMenuVisible,
              })}
            >
              <BurgerMenu
                active={props.isMenuVisible}
                onClick={props.onMenuClick}
                style={styles.burgerAbs}
                asCSS
              />

              <div className={styles.tiltedNavText}>Let's create</div>
              <nav className={cx(styles.menu, 'element')}>
                {props.isMenuVisible &&
                  menuItems.map((menuItem, index) => (
                    <Fade right delay={index * 100} duration={500} key={index}>
                      <div key={index} className={styles.linkWrapper}>
                        <Link
                          className={styles.link}
                          to={menuItem.to}
                          onClick={e => handleClick(e, menuItem)}
                        >
                          <h2>{menuItem.text}</h2>
                        </Link>
                      </div>
                    </Fade>
                  ))}
              </nav>
              <div className={styles.menuFooter}>
                <div className={styles.menuBottomList}>
                  <b>Social</b>
                  <br />
                  <a
                    href="http://instagram.com/fablefaber"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.behance.net/fableandfaber"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Behance
                  </a>
                  <a
                    href="https://www.linkedin.com/company/fable-faber/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Linkedin
                  </a>
                </div>
                <div className={styles.menuBottomList}>
                  <b>Email</b>
                  <br />
                  <a className="link" href="mailto: hello@fablefaber.com">
                    hello@fablefaber.com
                  </a>
                </div>
                <div className={styles.menuBottomList}>
                  <b>Phone</b>
                  <br />
                  <a className="link" href="tel:+020 365 28618">
                    020 365 28618
                  </a>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};

export default Menu;
