import cx from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styles from './Footer.module.scss';

const Footer = props => {
  return (
    <section className={cx('footer-anchor', styles.footer)}>
      <Container fluid style={{ flexGrow: 1, marginBottom: '-16px' }}>
        <Row nogutter style={{ height: '100%' }}>
          <Col sm={12} md={7} style={{ alignItems: 'center', display: 'flex' }}>
            <div>
              <div className={styles.findUs}>
                <span>Follow us on</span>
                <div>
                  <a
                    href="http://instagram.com/fablefaber"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Instagram
                  </a>
                  <a
                    href="https://www.behance.net/fableandfaber"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Behance
                  </a>
                  <a
                    href="https://www.linkedin.com/company/fable-faber/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    Linkedin
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={5}>
            <Row justify="end" className={styles.links}>
              <Col
                sm={12}
                md={6}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p>
                  <a className="link" href="mailto: hello@fablefaber.com">
                    hello@fablefaber.com
                  </a>
                </p>
              </Col>
              <Col
                sm={12}
                md={6}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p>Fable & Faber Ltd.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
